import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { Tooltip } from "@mui/material";
import { IoAddOutline, IoPricetagsOutline, IoTrashOutline } from "react-icons/io5";

import CurrentUser from "services/CurrentUser";
import AxiosInstance from "utils/AxiosInstance";
import { FormatPrice } from "utils/Format";
import { LoadingSpinner } from "components/common";
import { Button, Modal, OptionMenu } from "components/ui";
import { DataGrid } from "components/grid";

import ModalBillingSelectProduct from "./ModalBillingSelectProduct";
import ModalBillingAddProduct from "./ModalBillingAddProduct";
import ModalBillingSelectDiscount from "./ModalBillingSelectDiscount";
import ModalBillingAddDiscount from "./ModalBillingAddDiscount";

function ModalBillingCurrentMonth({ data, closeModal }) {
    const { t } = useTranslation();
    const currentUser = CurrentUser();

    const [modal, setModal] = useState({ show: false, data: null, type: null });
    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [billings, setBillings] = useState([]);

    const columns = [
        {
            field: "firstName",
            headerName: t("First name", { ns: "common" }),
            width: 180,
            renderCell: (params) => (
                <div className="flex justify-between items-center py-2 min-h-12 h-full">{params.row.firstName}</div>
            ),
        },
        {
            field: "lastName",
            headerName: t("Last name", { ns: "common" }),
            width: 180,
            renderCell: (params) => (
                <div className="flex justify-between items-center py-2 min-h-12 h-full">{params.row.lastName}</div>
            ),
        },
        {
            field: "fixed",
            headerName: t("Fixed payments", { ns: "common" }),
            flex: 1,
            minWidth: 360,
            renderCell: (params) => {
                let isEmpty = true;

                return (
                    <div className="flex items-center py-1 min-h-12 h-full">
                        {params.row.payments && params.row.payments.length > 0 ? (
                            <ul className="flex flex-col gap-1 w-full">
                                {params.row.payments[0].items.map((p, index) => {
                                    if (p.type.value === "FIXED") {
                                        isEmpty = false;
                                        let options = [];

                                        p.discount &&
                                            options.push({
                                                text: t("Remove discount", { ns: "button" }),
                                                icon: <IoPricetagsOutline size={"1.25em"} />,
                                                menuItemProps: {
                                                    onClick: () => handleRemoveDiscount(params.row, p),
                                                    disabled: isSubmitting || params.row.isMonthSettled,
                                                },
                                            });

                                        options.push({
                                            text: t("Remove product", { ns: "button" }),
                                            icon: <IoTrashOutline size={"1.25em"} />,
                                            menuItemProps: {
                                                onClick: () => handleRemoveProduct(params.row, p),
                                                disabled: isSubmitting || params.row.isMonthSettled,
                                            },
                                        });

                                        return (
                                            <li key={index} className="flex justify-between items-center w-full gap-1">
                                                <div className="flex justify-between items-center w-full gap-1">
                                                    <span>{p.name}</span>

                                                    {p.discount ? (
                                                        <Tooltip
                                                            placement="top"
                                                            title={
                                                                p.discount.name +
                                                                " - " +
                                                                (p.discount.type.value === "PERCENTAGE"
                                                                    ? p.discount.value + "%"
                                                                    : t("{{price, currency(PLN)}}", {
                                                                          price: p.discount.value,
                                                                      }))
                                                            }
                                                        >
                                                            <div className="flex flex-col">
                                                                <s className="text-xs">
                                                                    <FormatPrice price={p.price} />
                                                                </s>
                                                                {p.discount.type.value === "PERCENTAGE" ? (
                                                                    <FormatPrice
                                                                        price={
                                                                            p.price - p.price * (p.discount.value / 100)
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <FormatPrice price={p.price - p.discount.value} />
                                                                )}
                                                            </div>
                                                        </Tooltip>
                                                    ) : (
                                                        <FormatPrice price={p.price} />
                                                    )}
                                                </div>

                                                <OptionMenu options={options} />
                                            </li>
                                        );
                                    }

                                    return null;
                                })}

                                {isEmpty && "---"}
                            </ul>
                        ) : (
                            "---"
                        )}
                    </div>
                );
            },
        },
        {
            field: "presence",
            headerName: t("Presence payments", { ns: "common" }),
            flex: 1,
            minWidth: 360,
            renderCell: (params) => {
                let isEmpty = true;

                return (
                    <div className="flex items-center py-1 min-h-12 h-full">
                        {params.row.payments && params.row.payments.length > 0 ? (
                            <ul className="flex flex-col gap-1 w-full">
                                {params.row.payments[0].items.map((p, index) => {
                                    if (p.type.value === "PRESENCE") {
                                        isEmpty = false;
                                        let options = [];

                                        p.discount &&
                                            options.push({
                                                text: t("Remove discount", { ns: "button" }),
                                                icon: <IoPricetagsOutline size={"1.25em"} />,
                                                menuItemProps: {
                                                    onClick: () => handleRemoveDiscount(params.row, p),
                                                    disabled: isSubmitting || params.row.isMonthSettled,
                                                },
                                            });

                                        options.push({
                                            text: t("Remove product", { ns: "button" }),
                                            icon: <IoTrashOutline size={"1.25em"} />,
                                            menuItemProps: {
                                                onClick: () => handleRemoveProduct(params.row, p),
                                                disabled: isSubmitting || params.row.isMonthSettled,
                                            },
                                        });

                                        return (
                                            <li key={index} className="flex justify-between items-center w-full gap-1">
                                                <div className="flex justify-between items-center w-full gap-1">
                                                    <span>{p.name}</span>
                                                    <div className="flex flex-row items-center gap-1">
                                                        {p.discount ? (
                                                            <Tooltip
                                                                placement="top"
                                                                title={
                                                                    p.discount.name +
                                                                    " - " +
                                                                    (p.discount.type.value === "PERCENTAGE"
                                                                        ? p.discount.value + "%"
                                                                        : t("{{price, currency(PLN)}}", {
                                                                              price: p.discount.value,
                                                                          }))
                                                                }
                                                            >
                                                                <div className="flex flex-col">
                                                                    <s className="text-xs">
                                                                        <FormatPrice price={p.price} />
                                                                    </s>
                                                                    {p.discount.type.value === "PERCENTAGE" ? (
                                                                        <FormatPrice
                                                                            price={
                                                                                p.price -
                                                                                p.price * (p.discount.value / 100)
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <FormatPrice
                                                                            price={p.price - p.discount.value}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </Tooltip>
                                                        ) : (
                                                            <FormatPrice price={p.price} />
                                                        )}
                                                        <span>*</span>
                                                        <div className="form-field w-16">
                                                            <input
                                                                type="number"
                                                                className={"form-input !py-1 !px-2"}
                                                                min={0}
                                                                max={31}
                                                                step={1}
                                                                value={p.quantity}
                                                                onChange={(event) =>
                                                                    handleUpdateDaysInMonth(params.row, p, event)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <OptionMenu options={options} />
                                            </li>
                                        );
                                    }
                                    return null;
                                })}

                                {isEmpty && "---"}
                            </ul>
                        ) : (
                            "---"
                        )}
                    </div>
                );
            },
        },
        {
            field: "amount",
            headerName: t("Total Amount", { ns: "common" }),
            width: 140,
            renderCell: (params) => {
                return (
                    <div className="flex justify-between items-center py-2 min-h-12 h-full">
                        {params.row.payments && params.row.payments.length > 0 ? (
                            params.row.payments[0].discountAmount ? (
                                <div className="flex flex-col justify-center h-full leading-4">
                                    <s className="text-xs">
                                        <FormatPrice price={params.row.payments[0].amount} />
                                    </s>
                                    <span>
                                        <FormatPrice
                                            price={
                                                params.row.payments[0].amount - params.row.payments[0].discountAmount
                                            }
                                        />
                                    </span>
                                </div>
                            ) : (
                                <FormatPrice price={params.row.payments[0].amount} />
                            )
                        ) : (
                            "---"
                        )}
                    </div>
                );
            },
        },
        {
            field: "actions",
            headerName: "",
            sortable: false,
            disableExport: true,
            width: 120,
            renderCell: (params) => {
                let iconButtons = [
                    {
                        icon: <IoAddOutline size={"1.25rem"} />,
                        tooltipProps: {
                            title: t("Add product", { ns: "button" }),
                        },
                        menuItemProps: {
                            onClick: () => openModal({ kid: params.row, period: data.period }, "selectProduct"),
                            disabled: isSubmitting || params.row.isMonthSettled,
                        },
                    },
                    {
                        icon: <IoPricetagsOutline size={"1.25rem"} />,
                        tooltipProps: {
                            title: t("Add discount", { ns: "button" }),
                        },
                        menuItemProps: {
                            onClick: () => openModal({ kid: params.row, period: data.period }, "selectDiscount"),
                            disabled: isSubmitting || params.row.isMonthSettled,
                        },
                    },
                ];

                return <OptionMenu iconButtons={iconButtons} />;
            },
        },
    ];

    const openModal = (data, type = "view") => {
        setModal({ show: true, data: data, type: type });
    };

    const handleModalClose = () => {
        setModal({ show: false, data: null, type: null });
    };

    const handleAddProduct = (kid, product) => {
        setBillings(
            billings.map((k) => {
                if (k.id === kid.id) {
                    k.payments[0].items.push(product);
                    k.payments[0].amount = countAmount(k.payments[0].items);
                }
                return k;
            })
        );
    };

    const handleRemoveProduct = (kid, product) => {
        setBillings(
            billings.map((k) => {
                if (k.id === kid.id) {
                    k.payments[0].items.map((item, index) => {
                        if (item.id === product.id) {
                            k.payments[0].items.splice(index, 1);
                        }
                        return item;
                    });

                    k.payments[0].amount = countAmount(k.payments[0].items);
                    k.payments[0].discountAmount = countDiscount(k.payments[0].items);
                }
                return k;
            })
        );
    };

    const handleUpdateDaysInMonth = (row, product, event) => {
        setBillings(
            billings.map((k) => {
                if (k.id === row.id) {
                    k.payments[0].items.map((item) => {
                        if (item.id === product.id) {
                            item.quantity = event.target.value;
                        }
                        return item;
                    });

                    k.payments[0].amount = countAmount(k.payments[0].items);
                    k.payments[0].discountAmount = countDiscount(k.payments[0].items);
                }
                return k;
            })
        );
    };

    const handleAddDiscount = (kid, discount, product) => {
        setBillings(
            billings.map((k) => {
                if (k.id === kid.id) {
                    k.payments[0].items.map((item) => {
                        if (item.id === product.id) {
                            if (discount.type.value === "QUOTA" && discount.value > item.price) {
                                enqueueSnackbar(
                                    t("The value of the discount is greater than the price of the product.", {
                                        ns: "common",
                                    }),
                                    {
                                        variant: "error",
                                    }
                                );
                            } else {
                                item.discount = discount;
                            }
                        }
                        return item;
                    });

                    k.payments[0].amount = countAmount(k.payments[0].items);
                    k.payments[0].discountAmount = countDiscount(k.payments[0].items);
                }
                return k;
            })
        );
    };

    const handleRemoveDiscount = (kid, product) => {
        setBillings(
            billings.map((k) => {
                if (k.id === kid.id) {
                    k.payments[0].items.map((item, index) => {
                        if (item.id === product.id) {
                            delete item.discount;
                        }
                        return item;
                    });

                    k.payments[0].amount = countAmount(k.payments[0].items);
                    k.payments[0].discountAmount = countDiscount(k.payments[0].items);
                }
                return k;
            })
        );
    };

    function countAmount(items) {
        let amount = 0;

        items.map((item) => {
            let itemPrice = item.price;

            amount += itemPrice * item.quantity;

            return item;
        });

        return amount;
    }

    function countDiscount(items) {
        let discount = 0;

        items.map((item) => {
            let itemDiscount = 0;

            if (item.discount) {
                if (item.discount.type.value === "PERCENTAGE") {
                    itemDiscount = item.price * (item.discount.value / 100);
                } else {
                    itemDiscount = item.discount.value;
                }
            }

            discount += itemDiscount * item.quantity;

            return item;
        });

        return discount;
    }

    useEffect(() => {
        AxiosInstance({
            url:
                "institutions/" +
                currentUser.institution.id +
                "/billings/" +
                data.period.year() +
                "/" +
                (data.period.month() + 1) +
                "/proposal",
            method: "GET",
        })
            .then((response) => {
                setBillings(response.data.billings);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, [currentUser.institution.id, data]);

    const handleSubmit = async () => {
        setSubmitting(true);

        AxiosInstance({
            url: "institutions/" + currentUser.institution.id + "/billings",
            method: "POST",
            data: billings,
        })
            .then((response) => {
                setBillings(response.data.data.billings);

                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                handleModalClose();

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Modal
            handleClose={closeModal}
            title={t("Billing the month of {{period}}", { ns: "common", period: data.period.format("MMMM YYYY") })}
            size={"2xl"}
        >
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <DataGrid rows={billings} columns={columns} getRowHeight={() => "auto"} />
                    <Button className={"btn-primary mt-4"} onClick={() => handleSubmit()} disabled={isSubmitting}>
                        {t("Save", { ns: "button" })}
                    </Button>
                </>
            )}

            {modal.show && modal.data && modal.type === "selectProduct" && (
                <ModalBillingSelectProduct
                    data={modal.data}
                    handleAddProduct={handleAddProduct}
                    openModal={openModal}
                    closeModal={handleModalClose}
                />
            )}

            {modal.show && modal.data && modal.type === "selectDiscount" && (
                <ModalBillingSelectDiscount
                    data={modal.data}
                    handleAddDiscount={handleAddDiscount}
                    openModal={openModal}
                    closeModal={handleModalClose}
                />
            )}

            {modal.show && modal.data && modal.type === "addProduct" && (
                <ModalBillingAddProduct data={modal.data} openModal={openModal} closeModal={handleModalClose} />
            )}

            {modal.show && modal.data && modal.type === "addDiscount" && (
                <ModalBillingAddDiscount data={modal.data} openModal={openModal} closeModal={handleModalClose} />
            )}
        </Modal>
    );
}

export default ModalBillingCurrentMonth;
