import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "components/ui";
import DiscountForm from "./DiscountForm";

function ModalBillingAddDiscount({ closeModal }) {
    const { t } = useTranslation();

    return (
        <Modal handleClose={closeModal} title={t("Add discount", { ns: "common" })}>
            <DiscountForm isPopUp cancelButtonOnClick={closeModal} />
        </Modal>
    );
}

export default ModalBillingAddDiscount;
