import React from "react";
import { useTranslation } from "react-i18next";
import { IoSettingsOutline } from "react-icons/io5";

import { Dashboard } from "components/common";
import { ContentHeading } from "components/ui";
import ModulesList from "./components/ModulesList";
import SettingsList from "./components/SettingsList";

export const SettingsPage = () => {
    const { t } = useTranslation();

    return (
        <Dashboard contentClass={"settings"}>
            <ContentHeading tag="h3">
                <IoSettingsOutline size={"1.5rem"} />
                {t("Settings", { ns: "common" })}
            </ContentHeading>

            <ModulesList />
            <SettingsList />
        </Dashboard>
    );
};
