import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import CurrentUser from "services/CurrentUser";
import AxiosInstance from "utils/AxiosInstance";
import { LoadingSpinner } from "components/common";
import { Box, Button, ContentHeading } from "components/ui";
import { TextField } from "components/forms";
import { getInitialValues } from "components/forms/helpers";

function SettingsList() {
    const { t } = useTranslation();
    const currentUser = CurrentUser();

    const [isLoading, setLoading] = useState(true);
    const [settings, setSettings] = useState([]);

    const defaultValues = {
        accountNumber: "",
    };

    const validationSchema = Yup.object().shape({
        accountNumber: Yup.string()
            .required(t("This field is required.", { ns: "validation" }))
            .matches(
                /^[0-9]{26}$/,
                t("The allowed characters are numbers. The required length is 26 numbers.", {
                    ns: "validation",
                })
            ),
    });

    useEffect(() => {
        AxiosInstance({
            url: "institutions/" + currentUser.institution.id + "/settings",
            method: "GET",
        })
            .then((response) => {
                setSettings(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, []);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        AxiosInstance({
            url: "institutions/" + currentUser.institution.id + "/settings",
            method: "PUT",
            data: values,
        })
            .then((response) => {
                setSettings(response.data.data);
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Box>
            <ContentHeading tag="h4" className="mb-4">
                {t("Settings", { ns: "common" })}
            </ContentHeading>

            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <Formik
                    initialValues={getInitialValues(defaultValues, settings)}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        handleSubmit(values, setSubmitting, resetForm);
                    }}
                    validateOnMount
                    enableReinitialize
                >
                    {({
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched,
                        values,
                        validateForm,
                    }) => {
                        return (
                            <Form key={"additionalFeesForm"}>
                                <div className="grid grid-cols-6 gap-6">
                                    <Field
                                        component={TextField}
                                        key={"accountNumber"}
                                        name={"accountNumber"}
                                        // value={values.accountNumber}
                                        label={t("Bank Account No.", { ns: "common" })}
                                        required={true}
                                        maxLength={26}
                                        fieldClassName={"col-span-full"}
                                    />

                                    <Button
                                        type={"submit"}
                                        className={"btn-primary !col-start-1 col-span-1"}
                                        disabled={!isValid || isSubmitting}
                                    >
                                        {isSubmitting ? t("Saving", { ns: "button" }) : t("Save", { ns: "button" })}
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            )}
        </Box>
    );
}

export default SettingsList;
