import { useEffect } from "react";

import { useSessionStorage } from "hooks/useSessionStorage";
import AxiosInstance from "utils/AxiosInstance";
import AuthService from "./AuthService";

export default function CurrentUser() {
    const [user, setUser] = useSessionStorage("user", null);

    useEffect(() => {
        if (AuthService.isToken() && (!user || user === null)) {
            AxiosInstance({
                url: "users",
                method: "GET",
            }).then((response) => {
                setUser(response.data);
                sessionStorage.setItem("user", JSON.stringify(response.data));
            });
        }
    });

    return user;
}
