import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, Box, TextField } from "@mui/material";

import AxiosInstance from "utils/AxiosInstance";
import CurrentUser from "services/CurrentUser";
import { LoadingSpinner } from "components/common";
import { Button, Modal } from "components/ui";
import { FormatPrice } from "utils/Format";

function ModalBillingSelectProduct({ data, openModal, handleAddProduct, closeModal }) {
    const { t } = useTranslation();
    const currentUser = CurrentUser();

    const [isLoading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [currentProduct, setCurrentProduct] = useState(false);

    useEffect(() => {
        Promise.all([
            AxiosInstance({
                url:
                    "institutions/" +
                    currentUser.institution.id +
                    "/products/" +
                    data.period.year() +
                    "/" +
                    (data.period.month() + 1),
                method: "GET",
            }).then((response) => {
                setProducts(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    }, [currentUser.institution.id, data]);

    return (
        <Modal handleClose={closeModal} title={t("Select product", { ns: "common" })}>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div className="grid grid-cols-2 gap-4">
                    <Autocomplete
                        options={products}
                        renderInput={(params) => (
                            <TextField {...params} placeholder={t("Select...", { ns: "common" })} />
                        )}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => {
                            const { key, ...optionProps } = props;
                            return (
                                <Box key={key} component="li" {...optionProps}>
                                    {option.name} - <FormatPrice price={option.price} />
                                </Box>
                            );
                        }}
                        groupBy={(option) => t(option.type.name, { ns: "lexicons" })}
                        onChange={(option, value) => setCurrentProduct(value)}
                        className="col-span-full"
                    />
                    <Button className={"btn-primary"} onClick={() => handleAddProduct(data.kid, currentProduct)}>
                        {t("Add product", { ns: "button" })}
                    </Button>
                    <Button className={"btn-gray"} onClick={() => openModal({}, "addProduct")}>
                        {t("Add new product", { ns: "button" })}
                    </Button>
                </div>
            )}
        </Modal>
    );
}

export default ModalBillingSelectProduct;
