import React, { useEffect, useState } from "react";
import { AccessDenied, Dashboard, LoadingSpinner } from "components/common";

import AxiosInstance from "utils/AxiosInstance";
import BillingList from "./components/BillingList";

export const BillingsPage = () => {
    const [isLoading, setLoading] = useState(true);
    const [kids, setKids] = useState([]);

    useEffect(() => {
        AxiosInstance({
            url: "kids",
            method: "GET",
        })
            .then((response) => {
                setKids(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, []);

    return (
        <Dashboard contentClass={"billings additional-links " + (kids.length > 1 ? "lot-kids" : "one-kid")}>
            {isLoading ? <LoadingSpinner /> : kids ? <BillingList kids={kids} /> : <AccessDenied />}
        </Dashboard>
    );
};
