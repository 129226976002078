import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import CurrentUser from "services/CurrentUser";
import AxiosInstance from "utils/AxiosInstance";
import { useLexicons } from "hooks/useLexicons";
import { Button } from "components/ui";
import { getInitialValues } from "components/forms/helpers";
import { NumberField, PriceField, SelectField, TextField } from "components/forms";

function DiscountForm({ discount, cancelButtonLink, cancelButtonOnClick, isPopUp = false }) {
    const { t } = useTranslation();
    const currentUser = CurrentUser();
    const lexicons = useLexicons();

    const [discountTypeOptions, setDiscountTypeOptions] = useState([]);

    const defaultValues = {
        name: "",
        type: null,
        value: null,
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, t("Field too short. Minimum number of characters: {{min}}.", { ns: "validation", min: 3 }))
            .max(255, t("Field too long. Maximum number of characters: {{max}}.", { ns: "validation", max: 255 }))
            .required(t("The {{label}} field is required.", { ns: "validation", label: t("Name", { ns: "common" }) })),
        type: Yup.object().required(
            t("The {{label}} field is required.", { ns: "validation", label: t("Type", { ns: "common" }) })
        ),
        value: Yup.number().when("type", {
            is: (type) => type && type.value === "QUOTA",
            then: () =>
                Yup.number()
                    .min(0.1, t("Value too low. The minimum value is {{min}}.", { ns: "validation", min: 0.1 }))
                    .required(
                        t("The {{label}} field is required.", {
                            ns: "validation",
                            label: t("Value", { ns: "common" }),
                        })
                    ),
            otherwise: () =>
                Yup.number()
                    .integer(t("The value must be an integer.", { ns: "validation" }))
                    .min(1, t("Value too low. The minimum value is {{min}}.", { ns: "validation", min: 1 }))
                    .max(100, t("Value too high. The maximum value is {{max}}.", { ns: "validation", max: 100 }))
                    .required(
                        t("The {{label}} field is required.", {
                            ns: "validation",
                            label: t("Value", { ns: "common" }),
                        })
                    ),
        }),
    });

    useEffect(() => {
        if (lexicons) {
            setDiscountTypeOptions(lexicons["institution"]["discount"]["type"]);
        }
    }, [lexicons]);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        let url = "institutions/" + currentUser.institution.id + "/discounts";
        let method = "POST";

        if (discount) {
            url += "/" + discount.id;
            method = "PUT";
        }

        AxiosInstance({
            url: url,
            method: method,
            data: values,
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                if (!discount && response.status === 201) {
                    resetForm({ values: "" });
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Formik
            initialValues={getInitialValues(defaultValues, discount)}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(values, setSubmitting, resetForm);
            }}
            validateOnMount
            enableReinitialize
        >
            {({
                handleSubmit,
                isSubmitting,
                isValid,
                handleBlur,
                setFieldValue,
                setFieldTouched,
                values,
                validateForm,
            }) => {
                return (
                    <Form key={"discountForm"}>
                        <div className="grid grid-cols-6 gap-6">
                            <Field
                                component={TextField}
                                key={"name"}
                                name={"name"}
                                label={t("Name", { ns: "common" })}
                                required={true}
                                maxLength={255}
                                fieldClassName={"col-span-full"}
                            />
                            <Field
                                component={SelectField}
                                key={"type"}
                                name={"type"}
                                label={t("Discount type", { ns: "common" })}
                                required={true}
                                disabled={discount && true}
                                fieldClassName={"col-span-full"}
                                handleBlur={handleBlur}
                                options={discountTypeOptions}
                                getOptionLabel={(option) => t(option.name, { ns: "lexicons" })}
                                onChange={(event, value) => {
                                    setFieldValue("type", value);
                                    setFieldValue("value", null);
                                }}
                            />
                            {values.type && (
                                <Field
                                    component={values.type.value === "QUOTA" ? PriceField : NumberField}
                                    key={"value"}
                                    name={"value"}
                                    label={t("Value", { ns: "common" })}
                                    required={true}
                                    fieldClassName={"col-span-full"}
                                />
                            )}

                            <Button
                                type={"submit"}
                                className={
                                    "btn-primary !col-start-1 col-span-3" +
                                    (isPopUp ? "" : " md:col-span-2 xl:col-span-1")
                                }
                                disabled={!isValid || isSubmitting}
                            >
                                {isSubmitting ? t("Saving", { ns: "button" }) : t("Save", { ns: "button" })}
                            </Button>

                            {cancelButtonLink && (
                                <Link
                                    to={cancelButtonLink}
                                    className={
                                        "btn btn-gray col-span-3" + (isPopUp ? "" : " md:col-span-2 xl:col-span-1")
                                    }
                                    disabled={isSubmitting}
                                >
                                    {t("Cancel", { ns: "button" })}
                                </Link>
                            )}

                            {cancelButtonOnClick && (
                                <Button
                                    onClick={cancelButtonOnClick}
                                    className={
                                        "btn btn-gray col-span-3" + (isPopUp ? "" : " md:col-span-2 xl:col-span-1")
                                    }
                                    disabled={isSubmitting}
                                >
                                    {t("Cancel", { ns: "button" })}
                                </Button>
                            )}
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default DiscountForm;
