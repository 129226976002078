import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "components/ui";
import ProductForm from "./ProductForm";

function ModalBillingAddProduct({ closeModal }) {
    const { t } = useTranslation();

    return (
        <Modal handleClose={closeModal} title={t("Add product", { ns: "common" })}>
            <ProductForm isPopUp cancelButtonOnClick={closeModal} />
        </Modal>
    );
}

export default ModalBillingAddProduct;
