import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { IoCardOutline, IoWalletOutline } from "react-icons/io5";

import AxiosInstance from "utils/AxiosInstance";
import { FormatDate, FormatPrice } from "utils/Format";
import { DataGrid } from "components/grid";
import { LoadingSpinner } from "components/common";
import { Badge, Button, ContentHeading, OptionMenu } from "components/ui";
import { PaymentStatus } from "utils/PaymentStatus";

function BillingList({ kids }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [activeTab, setActiveTab] = useState("all");

    const [activeKid, setActiveKid] = useState(0);
    const [billings, setBillings] = useState([]);

    const columns = [
        {
            field: "brand",
            headerName:
                activeTab === "all"
                    ? t("Brand/Institution", { ns: "common" })
                    : activeTab === "by-brand"
                    ? t("Brand", { ns: "common" })
                    : t("Institution", { ns: "common" }),
            width: 240,
            valueGetter: (value, row) => {
                return `${value.name}`;
            },
        },
        {
            field: "activities",
            headerName:
                activeTab === "all"
                    ? t("Activities/Fees", { ns: "common" })
                    : activeTab === "by-brand"
                    ? t("Activities", { ns: "common" })
                    : t("Fees", { ns: "common" }),
            flex: 1,
            minWidth: 320,
            valueGetter: (value, row) => {
                return row.activity
                    .map((a) => {
                        return a.name;
                    })
                    .join(", ");
            },
        },
        {
            field: "dueDate",
            headerName: t("Due date", { ns: "common" }),
            width: 160,
            renderCell: (params) => {
                return <FormatDate date={params.row.dueDate} />;
            },
        },
        {
            field: "paymentDate",
            headerName: t("Payment date", { ns: "common" }),
            width: 160,
            renderCell: (params) => {
                return <FormatDate date={params.row.paymentDate} />;
            },
        },

        {
            field: "status",
            headerName: t("Status", { ns: "common" }),
            width: 140,
            valueGetter: (value, row) => {
                let status = PaymentStatus(row.status.value, row.dueDate, row.required);
                row.color = status.color;

                return `${status.title}`;
            },
            renderCell: (params) => {
                return (
                    <div className="flex items-center h-full">
                        <Badge color={params.row.color}>{t(params.value, { ns: "lexicons" })}</Badge>
                    </div>
                );
            },
        },
        {
            field: "period",
            headerName: t("Period", { ns: "common" }),
            width: 180,
            renderCell: (params) => {
                return (
                    <>
                        <FormatDate date={params.row.period.start} />
                        <span> - </span>
                        <FormatDate date={params.row.period.end} />
                    </>
                );
            },
        },
        {
            field: "amount",
            headerName: t("Amount", { ns: "common" }),
            width: 120,
            renderCell: (params) => {
                return params.row.status.value === "FREE" ? (
                    "---"
                ) : params.row.discountAmount ? (
                    <div className="flex flex-col justify-center h-full leading-4">
                        <s className="text-xs">
                            <FormatPrice price={params.value - params.row.additionalFeeAmount} />
                        </s>
                        <span>
                            <FormatPrice
                                price={params.value - params.row.additionalFeeAmount - params.row.discountAmount}
                            />
                        </span>
                    </div>
                ) : (
                    <FormatPrice price={params.value - params.row.additionalFeeAmount} />
                );
            },
        },
        {
            field: "actions",
            headerName: "",
            align: "right",
            sortable: false,
            disableExport: true,
            width: 180,
            renderCell: (params) => {
                let showPayButton = false;
                let options = [];

                if (
                    params.row.billing.active === true &&
                    params.row.required === true &&
                    (params.row.status.value === "NEW" ||
                        params.row.status.value === "FAILED" ||
                        params.row.status.value === "CANCELLED" ||
                        params.row.status.value === "REJECTED")
                ) {
                    showPayButton = true;
                }

                params.row.invoices.length > 0 &&
                    options.push({
                        pdf: 1,
                        props: {
                            invoiceSlug: params.row.invoices[0].slug,
                            invoiceFileName: params.row.invoices[0].fileName,
                        },
                    });

                return (
                    <div className="flex justify-end items-center h-full">
                        {showPayButton && (
                            <Button
                                className={"btn-sm btn-primary"}
                                onClick={() => handleSubmit(params.row.orderId)}
                                disabled={isSubmitting}
                            >
                                {t("Pay", { ns: "button" })}
                            </Button>
                        )}
                        <OptionMenu options={options} />
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        setBillings([]);

        if (kids.length) {
            setLoading(true);

            let url = "kids/" + kids[activeKid].id + "/payments";

            if (activeTab !== "all") {
                url = url + "/" + activeTab;
            }

            AxiosInstance({
                url: url,
                method: "GET",
            })
                .then((response) => {
                    setBillings(response.data);

                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                })
                .catch((error) => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                });
        } else {
            setLoading(false);
        }
    }, [activeKid, searchParams, activeTab]);

    const handleSubmit = (orderId) => {
        setSubmitting(true);

        navigate("/payment/" + orderId);
    };

    return (
        <>
            <ContentHeading tag="h3">
                <IoWalletOutline size={"1.5rem"} />
                {t("Billings", { ns: "common" })}
            </ContentHeading>

            {kids.length > 1 && (
                <div className="flex flex-col md:flex-row gap-2">
                    <div className="content-links">
                        {kids.map((kid, index) => (
                            <Button
                                className={index === activeKid ? "btn-primary" : "btn-dark-outline btn-opacity"}
                                onClick={() => setActiveKid(index)}
                                key={index}
                            >
                                {kid.fullName}
                            </Button>
                        ))}
                    </div>
                </div>
            )}

            <div className="flex flex-col md:flex-row gap-2">
                <div className="content-links">
                    <Button
                        className={"all" === activeTab ? "btn-primary" : "btn-dark-outline btn-opacity"}
                        onClick={() => setActiveTab("all")}
                        key={"all"}
                    >
                        {t("All", { ns: "button" })}
                    </Button>
                    <Button
                        className={"by-brand" === activeTab ? "btn-primary" : "btn-dark-outline btn-opacity"}
                        onClick={() => setActiveTab("by-brand")}
                        key={"by-brand"}
                    >
                        {t("With brand", { ns: "button" })}
                    </Button>
                    <Button
                        className={"by-institution" === activeTab ? "btn-primary" : "btn-dark-outline btn-opacity"}
                        onClick={() => setActiveTab("by-institution")}
                        key={"by-institution"}
                    >
                        {t("With institution", { ns: "button" })}
                    </Button>
                </div>
            </div>

            {isLoading ? <LoadingSpinner /> : <DataGrid rows={billings} columns={columns} />}
        </>
    );
}

export default BillingList;
