import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import CurrentUser from "services/CurrentUser";
import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, Dashboard, LoadingSpinner } from "components/common";
import DiscountForm from "./components/DiscountForm";
import { Box, ContentHeading } from "components/ui";
import { useTranslation } from "react-i18next";

export const DiscountEditPage = () => {
    const { t } = useTranslation();

    const params = useParams();
    const currentUser = CurrentUser();
    const [isLoading, setLoading] = useState(true);
    const [discount, setDiscount] = useState(null);

    useEffect(() => {
        AxiosInstance({
            url: "institutions/" + currentUser.institution.id + "/discounts/" + params.discountId,
            method: "GET",
        })
            .then((response) => {
                setDiscount(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, [currentUser.institution.id, params.discountId]);

    return (
        <Dashboard contentClass={"discount"}>
            <ContentHeading tag="h3">{t("Edit discount", { ns: "common" })}</ContentHeading>

            <Box>
                {isLoading ? (
                    <LoadingSpinner />
                ) : discount ? (
                    <DiscountForm discount={discount} cancelButtonLink="/institution/products" />
                ) : (
                    <AccessDenied />
                )}
            </Box>
        </Dashboard>
    );
};
