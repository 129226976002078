import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IoCreateOutline } from "react-icons/io5";

import CurrentUser from "services/CurrentUser";
import AxiosInstance from "utils/AxiosInstance";
import { LoadingSpinner } from "components/common";
import { OptionMenu } from "components/ui";
import { DataGrid } from "components/grid";
import { FormatPrice } from "utils/Format";

function ProductsList() {
    const { t } = useTranslation();

    const currentUser = CurrentUser();
    const [isLoading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);

    const columns = [
        { field: "name", headerName: t("Name", { ns: "common" }), miWidth: 240, flex: 1 },
        {
            field: "price",
            headerName: t("Price", { ns: "common" }),
            width: 240,
            renderCell: (params) => {
                return <FormatPrice price={params.value} />;
            },
        },
        {
            field: "period",
            headerName: t("Payment type", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) => `${row.period ? t(row.period.name, { ns: "lexicons" }) : "---"}`,
        },
        {
            field: "type",
            headerName: t("Billing type", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) => `${row.type ? t(row.type.name, { ns: "lexicons" }) : "---"}`,
        },
        {
            field: "actions",
            headerName: "",
            sortable: false,
            disableExport: true,
            width: 120,
            renderCell: (params) => {
                let options = [];
                options.push({
                    text: t("Edit", { ns: "button" }),
                    icon: <IoCreateOutline size={"1.25em"} />,
                    href: "/institution/products/" + params.row.id + "/edit",
                });

                return <OptionMenu options={options} />;
            },
        },
    ];

    useEffect(() => {
        handleLoadData();
    }, []);

    const handleLoadData = () => {
        AxiosInstance({
            url: "institutions/" + currentUser.institution.id + "/products",
            method: "GET",
        })
            .then((response) => {
                setProducts(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    };

    return <>{isLoading ? <LoadingSpinner /> : <DataGrid rows={products} columns={columns} />}</>;
}

export default ProductsList;
