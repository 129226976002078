import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, Box, TextField } from "@mui/material";

import AxiosInstance from "utils/AxiosInstance";
import CurrentUser from "services/CurrentUser";
import { LoadingSpinner } from "components/common";
import { Button, Modal } from "components/ui";
import { FormatPrice } from "utils/Format";

function ModalBillingSelectDiscount({ data, openModal, handleAddDiscount, closeModal }) {
    const { t } = useTranslation();
    const currentUser = CurrentUser();

    const [isLoading, setLoading] = useState(true);
    const [discounts, setDiscounts] = useState([]);
    const [currentDiscount, setCurrentDiscount] = useState(null);
    const [currentProduct, setCurrentProduct] = useState(null);

    useEffect(() => {
        Promise.all([
            AxiosInstance({
                url: "institutions/" + currentUser.institution.id + "/discounts",
                method: "GET",
            }).then((response) => {
                setDiscounts(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    }, [currentUser.institution.id, data]);

    return (
        <Modal handleClose={closeModal} title={t("Select discount", { ns: "common" })}>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div className="grid grid-cols-2 gap-4">
                    <Autocomplete
                        options={data.kid.payments[0].items}
                        renderInput={(params) => (
                            <TextField {...params} placeholder={t("Select...", { ns: "common" })} />
                        )}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => {
                            const { key, ...optionProps } = props;
                            return (
                                <Box key={key} component="li" {...optionProps}>
                                    {option.name} - <FormatPrice price={option.price} />
                                </Box>
                            );
                        }}
                        onChange={(option, value) => setCurrentProduct(value)}
                        className="col-span-full"
                    />
                    <Autocomplete
                        options={discounts}
                        renderInput={(params) => (
                            <TextField {...params} placeholder={t("Select...", { ns: "common" })} />
                        )}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => {
                            const { key, ...optionProps } = props;
                            return (
                                <Box key={key} component="li" {...optionProps}>
                                    {option.name} -{" "}
                                    {option.type.value === "PERCENTAGE" ? (
                                        option.value + "%"
                                    ) : (
                                        <FormatPrice price={option.value} />
                                    )}
                                </Box>
                            );
                        }}
                        onChange={(option, value) => setCurrentDiscount(value)}
                        className="col-span-full"
                    />
                    <Button
                        className={"btn-primary"}
                        onClick={() => handleAddDiscount(data.kid, currentDiscount, currentProduct)}
                        disabled={!currentDiscount || !currentProduct}
                    >
                        {t("Add discount", { ns: "button" })}
                    </Button>
                    <Button className={"btn-gray"} onClick={() => openModal({}, "addDiscount")}>
                        {t("Add new discount", { ns: "button" })}
                    </Button>
                </div>
            )}
        </Modal>
    );
}

export default ModalBillingSelectDiscount;
