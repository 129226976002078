import { useTranslation } from "react-i18next";
import { FormatDate, FormatTime } from "utils/Format";
import { Modal } from "components/ui";
import CurrentUser from "services/CurrentUser";
import { DataGrid } from "components/grid";
import { GroupIcon } from "pages/Institution/components/GroupIcon";

export const EventPopUp = ({ store, eventPopUpOpen, handleEventPopUpToggle }) => {
    const { t } = useTranslation();
    const currentUser = CurrentUser();

    const event =
        store.selectedEvent !== null && store.selectedEvent.id.length
            ? store.events.find((e) => e.id === parseInt(store.selectedEvent.id))
            : null;

    const columns = [
        { field: "fullName", headerName: t("Full name", { ns: "common" }), width: 240 },
        {
            field: "group",
            headerName: t("Group", { ns: "common" }),
            width: 180,
            valueGetter: (value, row) => `${(value && value.name) || "---"}`,
            valueFormatter: (value) => `${value}`,
            renderCell: (params) => {
                return (
                    <div className="flex items-center gap-4">
                        <GroupIcon icon={params.row.group.icon} color={params.row.group.color} />
                        {params.row.group.name || ""}
                    </div>
                );
            },
        },
        {
            field: "actions",
            headerName: "",
            sortable: false,
            disableExport: true,
            width: 80,
            renderCell: (params) => {
                return params.row.joinStatus === "accepted" ? <></> : "";
            },
        },
    ];

    return (
        <Modal
            size={"lg"}
            open={eventPopUpOpen}
            handleClose={handleEventPopUpToggle}
            title={t("Information about activity", { ns: "common" })}
        >
            {event !== null && event.title.length ? (
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2">
                            <strong>{t("Name", { ns: "common" })}:</strong>
                            {event.title}
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Trainer", { ns: "common" })}:</strong>
                            {event.data.trainers
                                ? event.data.trainers
                                      .map((trainer) => {
                                          return trainer.fullName;
                                      })
                                      .join(", ")
                                : t("lack", { ns: "common" })}
                        </div>
                        {event.data.enrollment.type.value === "IN_INSTITUTION" && (
                            <>
                                <div className="flex items-center gap-2">
                                    <strong>{t("Institution", { ns: "common" })}:</strong>
                                    {event.data.institution.name}
                                </div>
                                <div className="flex items-center gap-2">
                                    <strong>{t("Group", { ns: "common" })}:</strong>
                                    {event.data.groups
                                        ? event.data.groups
                                              .map((group) => {
                                                  return group.name;
                                              })
                                              .join(", ")
                                        : t("lack", { ns: "common" })}
                                </div>
                            </>
                        )}
                    </div>

                    <div className="border-t"></div>

                    <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2">
                            <strong>{t("Start", { ns: "common" })}:</strong>
                            <FormatDate date={event.start} />
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Hour", { ns: "common" })}:</strong>
                            <FormatTime date={event.start} formatting={{ hour: "numeric", minute: "numeric" }} />
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("End", { ns: "common" })}:</strong>
                            <FormatDate date={event.end} />
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Hour", { ns: "common" })}:</strong>
                            <FormatTime date={event.end} formatting={{ hour: "numeric", minute: "numeric" }} />
                        </div>
                        <div className="flex items-center gap-2">
                            <strong>{t("Description", { ns: "common" })}:</strong>
                            {event.description ? event.description : t("lack", { ns: "common" })}
                        </div>
                    </div>

                    {!currentUser.roles.includes("ROLE_PARENT") && (
                        <>
                            <div className="border-t"></div>

                            <div className="flex items-center gap-2">
                                <strong>{t("List of kids", { ns: "common" })}:</strong>
                            </div>

                            <DataGrid
                                rows={event.data.kids}
                                columns={columns}
                                enableSearch={true}
                                staticHeight={true}
                                fileName={"ListaDzieci"}
                            />
                        </>
                    )}
                </div>
            ) : null}
        </Modal>
    );
};
