import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import CurrentUser from "services/CurrentUser";
import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, Dashboard, LoadingSpinner } from "components/common";
import ProductForm from "./components/ProductForm";
import { Box, ContentHeading } from "components/ui";
import { useTranslation } from "react-i18next";

export const ProductEditPage = () => {
    const { t } = useTranslation();

    const params = useParams();
    const currentUser = CurrentUser();
    const [isLoading, setLoading] = useState(true);
    const [product, setProduct] = useState(null);

    useEffect(() => {
        AxiosInstance({
            url: "institutions/" + currentUser.institution.id + "/products/" + params.productId,
            method: "GET",
        })
            .then((response) => {
                setProduct(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, [currentUser.institution.id, params.productId]);

    return (
        <Dashboard contentClass={"product"}>
            <ContentHeading tag="h3">{t("Edit product", { ns: "common" })}</ContentHeading>

            <Box>
                {isLoading ? (
                    <LoadingSpinner />
                ) : product ? (
                    <ProductForm product={product} cancelButtonLink="/institution/products" />
                ) : (
                    <AccessDenied />
                )}
            </Box>
        </Dashboard>
    );
};
