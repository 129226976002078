import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AxiosInstance from "utils/AxiosInstance";
import { AccessDenied, LoadingSpinner } from "components/common";
import { Box, Button } from "components/ui";
import { FormatPrice } from "utils/Format";
import { PayTelForm } from "components/payments/PayTelForm";
import CurrentUser from "services/CurrentUser";

export const PaymentPage = () => {
    const params = useParams();
    const { t } = useTranslation();

    const navigate = useNavigate();
    const currentUser = CurrentUser();

    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [status, setStatus] = useState(null);
    const [activeGateways, setActiveGateways] = useState([]);
    const [activeGateway, setActiveGateway] = useState(null);
    const [formData, setFormData] = useState(null);
    const [payment, setPayment] = useState(null);

    useEffect(() => {
        Promise.all([
            AxiosInstance({
                url: "payments/gateways/active",
                method: "POST",
                data: {
                    orderId: params.orderId,
                },
            }).then((response) => {
                setActiveGateways(response.data);
            }),

            AxiosInstance({
                url: "payments/" + params.orderId,
                method: "GET",
            }).then((response) => {
                setPayment(response.data);
            }),
        ]).then(() => {
            setTimeout(() => {
                setLoading(false);
            }, 300);
        });
    }, [params.orderId]);

    const handleGoBack = () => {
        setSubmitting(true);

        if (currentUser.accountType === "parent") {
            navigate("/parent/billings");
        } else {
            navigate("/");
        }
    };

    const handleSubmit = async () => {
        setSubmitting(true);

        AxiosInstance({
            url: "payments/pay",
            method: "POST",
            data: {
                orderId: params.orderId,
                gateway: activeGateway,
            },
        })
            .then((response) => {
                if (activeGateway === "PAY_TEL" && response.data.formContext) {
                    setStatus(response.data.paymentStatus);
                    setFormData(response.data);
                } else if ((activeGateway === "IMOJE" || activeGateway === "IMOJE_V2") && response.data.payUrl) {
                    window.location.replace(response.data.payUrl);
                    return;
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return isLoading ? (
        <LoadingSpinner isFull={true} />
    ) : status === "PENDING" && formData ? (
        <div className={"flex flex-col items-center h-full w-full p-6 lg:p-12 gap-8"}>
            <PayTelForm data={formData} />

            <Button
                type={"submit"}
                className={"btn btn-dark-outline"}
                onClick={() => handleGoBack(params.orderId)}
                disabled={isSubmitting}
            >
                {t("Return to the dashboard", { ns: "button" })}
            </Button>
        </div>
    ) : activeGateways ? (
        <div className={"flex flex-col items-center justify-between h-screen w-full p-6 lg:p-12 gap-8"}>
            <div className={"flex flex-row items-start gap-12"}>
                {activeGateways.length > 0 ? (
                    <div className={"flex flex-col items-center gap-8"}>
                        <h3>{t("Choose a payment gateway", { ns: "common" })}</h3>
                        <div className={"flex flex-row items-center p-6 gap-8"}>
                            {activeGateways.map((gateway) => (
                                <Box
                                    key={gateway.value}
                                    className={
                                        "cursor-pointer border " +
                                        (activeGateway && activeGateway === gateway.value ? "border-primary" : "")
                                    }
                                    onClick={() => !isSubmitting && setActiveGateway(gateway.value)}
                                >
                                    <img src={gateway.img} alt={gateway.name} />
                                </Box>
                            ))}
                        </div>
                        <Button
                            type={"submit"}
                            className={"btn btn-primary"}
                            onClick={() => handleSubmit()}
                            disabled={!activeGateway || isSubmitting}
                        >
                            {t("Go to payments", { ns: "button" })}
                        </Button>
                    </div>
                ) : (
                    <>
                        <h3>{t("No active payment gateways.", { ns: "common" })}</h3>
                        <p className="text-center">
                            {t("There is currently no active payment gateway.", { ns: "common" })}
                            <br />
                            {t("Try later or contact your system administrator.", { ns: "common" })}
                        </p>
                    </>
                )}
                {payment && (
                    <Box className="flex flex-col gap-4 max-w-96 text-sm">
                        <h4>{t("Details", { ns: "common" })}</h4>
                        <div className="flex flex-col gap-1">
                            {payment.items.map((item, index) => {
                                return item.isAdditionalFee === false ? (
                                    <div className="grid grid-cols-6 gap-3 items-start" key={index}>
                                        <span className="col-span-4">{item.name}</span>
                                        <span className="col-span-2 text-base font-semibold text-right">
                                            {item.discount ? (
                                                item.discount.type.value === "PERCENTAGE" ? (
                                                    <FormatPrice
                                                        price={
                                                            (item.unitPrice -
                                                                item.unitPrice * (item.discount.value / 100)) *
                                                            item.quantity
                                                        }
                                                    />
                                                ) : (
                                                    <FormatPrice
                                                        price={(item.unitPrice - item.discount.value) * item.quantity}
                                                    />
                                                )
                                            ) : (
                                                <FormatPrice price={item.unitPrice * item.quantity} />
                                            )}
                                        </span>
                                    </div>
                                ) : null;
                            })}
                        </div>
                        {payment.additionalFeeAmount && (
                            <>
                                <div className="flex">
                                    <hr className="w-full"></hr>
                                </div>
                                <div className="grid grid-cols-6 gap-3 items-start">
                                    <span className="col-span-4">{t("Total", { ns: "common" })}</span>
                                    <span className="col-span-2 text-base font-semibold text-right">
                                        <FormatPrice
                                            price={
                                                payment.amount - payment.discountAmount - payment.additionalFeeAmount
                                            }
                                        />
                                    </span>
                                </div>
                                <div className="flex flex-col gap-1">
                                    {payment.items.map((item, index) => {
                                        return item.isAdditionalFee ? (
                                            <div className="grid grid-cols-6 gap-3 items-start" key={index}>
                                                <span className="col-span-4">{item.name}</span>
                                                <span className="col-span-2 text-base font-semibold text-right">
                                                    <FormatPrice price={item.unitPrice} />
                                                </span>
                                            </div>
                                        ) : null;
                                    })}
                                </div>
                            </>
                        )}
                        <div className="flex">
                            <hr className="w-full"></hr>
                        </div>
                        <div className="grid grid-cols-6 gap-3 items-start">
                            <span className="col-span-4">{t("Amount owed", { ns: "common" })}</span>
                            <span className="col-span-2 text-base font-semibold text-right">
                                <FormatPrice price={payment.amount - payment.discountAmount} />
                            </span>
                        </div>
                    </Box>
                )}
            </div>
            <Button
                type={"submit"}
                className={"btn btn-dark-outline"}
                onClick={() => handleGoBack(params.orderId)}
                disabled={isSubmitting}
            >
                {t("Return to the dashboard", { ns: "button" })}
            </Button>
        </div>
    ) : (
        <div className={"flex flex-col items-center min-h-screen w-full p-12 gap-8"}>
            <AccessDenied />

            <Button
                type={"submit"}
                className={"btn btn-dark-outline"}
                onClick={() => handleGoBack(params.orderId)}
                disabled={isSubmitting}
            >
                {t("Return to the dashboard", { ns: "button" })}
            </Button>
        </div>
    );
};
