import React from "react";

import { Dashboard } from "components/common";
import ProductForm from "./components/ProductForm";
import { Box, ContentHeading } from "components/ui";
import { useTranslation } from "react-i18next";

export const ProductAddPage = () => {
    const { t } = useTranslation();

    return (
        <Dashboard contentClass={"product"}>
            <ContentHeading tag="h3">{t("Add product", { ns: "common" })}</ContentHeading>

            <Box>
                <ProductForm cancelButtonLink="/institution/products" />
            </Box>
        </Dashboard>
    );
};
