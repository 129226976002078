import React from "react";
import { t } from "i18next";

export const PageNotFound = ({ message }) => {
    return (
        <div className="grid h-screen w-full p-8">
            <h2 className="m-auto">{t("404 | Page Not Found", { ns: "common" })}</h2>
            {message && <p className="m-auto mt-4 text-center">{message}</p>}
        </div>
    );
};
