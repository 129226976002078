import React from "react";
import { useTranslation } from "react-i18next";

import { Dashboard } from "components/common";
import { Box, ContentHeading } from "components/ui";
import DiscountForm from "./components/DiscountForm";

export const DiscountAddPage = () => {
    const { t } = useTranslation();

    return (
        <Dashboard contentClass={"discount"}>
            <ContentHeading tag="h3">{t("Add discount", { ns: "common" })}</ContentHeading>

            <Box>
                <DiscountForm cancelButtonLink="/institution/products" />
            </Box>
        </Dashboard>
    );
};
