import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoAddOutline, IoPricetagsOutline, IoSchoolOutline } from "react-icons/io5";

import { Dashboard } from "components/common";
import { Button, ContentHeading } from "components/ui";
import ProductsList from "./components/ProductsList";
import DiscountsList from "./components/DiscountsList";

export const ProductsPage = () => {
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState("products");

    return (
        <Dashboard contentClass={"products additional-links"}>
            <ContentHeading
                tag="h3"
                showBtn={true}
                btnUrl={"discounts" === activeTab ? "/institution/discounts/new" : "/institution/products/new"}
                btnIcon={<IoAddOutline size={"1.125rem"} />}
                btnLabel={
                    "discounts" === activeTab ? t("Add discount", { ns: "button" }) : t("Add product", { ns: "button" })
                }
            >
                {"discounts" === activeTab ? (
                    <>
                        <IoPricetagsOutline size={"1.5rem"} />
                        {t("Discounts", { ns: "button" })}
                    </>
                ) : (
                    <>
                        <IoSchoolOutline size={"1.5rem"} />
                        {t("Products", { ns: "button" })}
                    </>
                )}
            </ContentHeading>

            <div className="flex flex-col md:flex-row gap-2">
                <div className="content-links">
                    <Button
                        className={"products" === activeTab ? "btn-primary" : "btn-dark-outline btn-opacity"}
                        onClick={() => setActiveTab("products")}
                        key={"products"}
                    >
                        {t("Products", { ns: "button" })}
                    </Button>
                    <Button
                        className={"discounts" === activeTab ? "btn-primary" : "btn-dark-outline btn-opacity"}
                        onClick={() => setActiveTab("discounts")}
                        key={"discounts"}
                    >
                        {t("Discounts", { ns: "button" })}
                    </Button>
                </div>
            </div>

            {"discounts" === activeTab ? <DiscountsList /> : <ProductsList />}
        </Dashboard>
    );
};
