import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel } from "@mui/material";
import { enqueueSnackbar } from "notistack";

import CurrentUser from "services/CurrentUser";
import AxiosInstance from "utils/AxiosInstance";
import { LoadingSpinner } from "components/common";
import { Box, ContentHeading } from "components/ui";

function ModulesList() {
    const { t } = useTranslation();
    const currentUser = CurrentUser();

    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [modules, setModules] = useState([]);

    useEffect(() => {
        AxiosInstance({
            url: "accounts/modules",
            method: "GET",
        })
            .then((response) => {
                setModules(response.data);

                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            });
    }, []);

    const handleUpdateModule = (moduleId) => {
        setSubmitting(true);

        let values = {
            institutionId: currentUser.institution.id,
            moduleId: moduleId,
        };

        AxiosInstance({
            url: "accounts/modules",
            method: "PUT",
            data: values,
        })
            .then((response) => {
                setModules(response.data.data);
                sessionStorage.removeItem("user");

                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                setTimeout(() => {
                    window.location.reload();
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <Box>
            <ContentHeading tag="h4" className="mb-4">
                {t("Active modules", { ns: "common" })}
            </ContentHeading>

            {isLoading ? (
                <LoadingSpinner />
            ) : (
                modules.map((m, index) => (
                    <React.Fragment key={index}>
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox
                                    onChange={(e) => {
                                        handleUpdateModule(m.id);
                                    }}
                                    name={"module[" + index + "][active]"}
                                    checked={m.isEnabled}
                                    disabled={isSubmitting ? true : m.isActive === true ? false : true}
                                />
                            }
                            label={m.name}
                            disabled={isSubmitting ? true : m.isActive === true ? false : true}
                        />

                        {m.description && <p className={"text-sm italic m-0 "}>{m.description}</p>}
                    </React.Fragment>
                ))
            )}
        </Box>
    );
}

export default ModulesList;
