import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

import CurrentUser from "services/CurrentUser";
import AxiosInstance from "utils/AxiosInstance";
import { useLexicons } from "hooks/useLexicons";
import { AdvancedForm } from "components/forms/AdvancedForm";

function ProductForm({ product, cancelButtonLink, cancelButtonOnClick, isPopUp = false }) {
    const { t } = useTranslation();
    const currentUser = CurrentUser();
    const lexicons = useLexicons();

    const [productPeriodOptions, setProductPeriodOptions] = useState([]);
    const [productTypeOptions, setProductTypeOptions] = useState([]);

    const formSchema = [
        {
            name: "name",
            label: t("Name", { ns: "common" }),
            componentType: "text",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-full",
        },
        {
            name: "description",
            label: t("Description", { ns: "common" }),
            componentType: "textarea",
            required: false,
            fieldClassName: "col-span-full",
        },
        {
            name: "price",
            label: t("Price", { ns: "common" }),
            componentType: "price",
            required: true,
            maxLength: 255,
            fieldClassName: "col-span-full",
        },
        {
            name: "period",
            label: t("Payment type", { ns: "common" }),
            componentType: "select",
            required: true,
            disabled: product && true,
            fieldClassName: "col-span-full",
            options: productPeriodOptions,
            getOptionLabel: (option) => t(option.name, { ns: "lexicons" }),
        },
        {
            name: "type",
            label: t("Billing type", { ns: "common" }),
            componentType: "select",
            required: true,
            disabled: product && true,
            fieldClassName: "col-span-full",
            options: productTypeOptions,
            getOptionLabel: (option) => t(option.name, { ns: "lexicons" }),
        },
    ];

    useEffect(() => {
        if (lexicons) {
            setProductPeriodOptions(lexicons["institution"]["product"]["period"]);
            setProductTypeOptions(lexicons["institution"]["product"]["type"]);
        }
    }, [lexicons]);

    const handleSubmit = async (values, setSubmitting, resetForm) => {
        setSubmitting(true);

        let url = "institutions/" + currentUser.institution.id + "/products";
        let method = "POST";

        if (product) {
            url += "/" + product.id;
            method = "PUT";
        }

        AxiosInstance({
            url: url,
            method: method,
            data: values,
        })
            .then((response) => {
                enqueueSnackbar(t(response.data.message, { ns: "common" }), {
                    variant: "success",
                });

                if (!product && response.status === 201) {
                    resetForm({ values: "" });
                }

                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            })
            .catch((error) => {
                setTimeout(() => {
                    setSubmitting(false);
                }, 300);
            });
    };

    return (
        <AdvancedForm
            isPopUp={isPopUp}
            schema={formSchema}
            onSubmit={handleSubmit}
            initialValues={product}
            buttonLabel={t("Save", { ns: "button" })}
            submittingButtonLabel={t("Saving", { ns: "button" })}
            cancelButtonLink={cancelButtonLink}
            cancelButtonOnClick={cancelButtonOnClick}
            cancelButtonLabel={t("Cancel", { ns: "button" })}
        />
    );
}

export default ProductForm;
